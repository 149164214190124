<template>
  <div>
    <v-bottom-navigation height="80px" color="white">
      <div class="bottom-flex">
        <div class="impress-buttons">
          <!-- <router-link to="/impress">
            <v-btn color="secondary" height="25">Impressum</v-btn>
          </router-link> -->
        </div>
        <div class="made-by">
          <span class="font-weight-light text-caption text-color-dark"
            >Made with </span
          ><span><v-icon x-small color="red">mdi-heart</v-icon></span
          ><span class="font-weight-light text-caption text-color-dark">
            in Stuttgart by
            <div style="text-align: center">
              <a target="_blank" href="https://www.connectedlogic.de"> connectedlogic.de</a>
            </div></span
          >
        </div>
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.bottom-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.impress-buttons {
  text-align: center;
}
.made-by {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}
</style>