<template>
  <v-card>
    <div class="top-buttons">
      <v-btn @click="$emit('toggleDialog')" icon>
        <v-icon large secondary>mdi-close</v-icon></v-btn
      >
    </div>
    <v-card-title class="text-h5 grey lighten-2">
      <div class="dialog-title">Unsere Öffnungszeiten</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text style="margin-top: 1rem">
      <div class="is-open-container">
        <div v-if="isOpen" class="is-open">
          <div class="is-open-line">
            <div>
              <v-icon small color="light-green accent-3">mdi-circle</v-icon>
            </div>
            Wir haben geöffnet!
          </div>
          <div style="padding-bottom: 0.5rem">
            Kommen Sie doch vorbei, oder rufen an!
          </div>

          <v-btn @click="openMap" icon><v-icon>mdi-google-maps</v-icon></v-btn>
          <!-- <v-btn @click="$emit('toggleDialog', 'dialog-telephone')" icon
            ><v-icon>mdi-phone</v-icon></v-btn
          > -->
        </div>
        <div v-if="!isOpen" class="is-open">
          <div class="is-open-line">
            <div><v-icon small color="red">mdi-circle</v-icon></div>
            <div>Wir haben leider geschlossen...</div>
          </div>
          <div style="padding-bottom: 0.5rem">
            Senden Sie uns doch eine Nachricht!
          </div>
          <!-- <v-btn
            @click="$emit('toggleDialog', 'dialog-whatsapp')"
            icon
            elevation="0"
            ><v-icon>mdi-whatsapp</v-icon></v-btn
          > -->
          <v-btn
            @click="$emit('toggleDialog', 'dialog-contact-formular')"
            icon
            elevation="0"
            ><v-icon>mdi-email</v-icon></v-btn
          >
        </div>
      </div>

      <div v-for="(opening, index) in openings" :key="index">
        <div class="sub-entry">
          <div class="entry-day">{{ index }}</div>
          <div v-if="opening.length > 0" class="entry-hour">
            <div>{{ opening[0] }} <span>-</span> {{ opening[1] }}</div>
            <div v-if="opening[2]">
              {{ opening[2] }} <span>-</span> {{ opening[3] }}
            </div>
          </div>
          <div class="entry-hour" v-else>Geschlossen</div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// Import dayjs
import dayjs from "dayjs";
import "dayjs/locale/de";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
// Configure dayjs
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.locale("de");

import openings from "../../data/openings";

import DialogTelephone from "../dialogs/DialogTelephone.vue";
import DialogContactFormular from "../dialogs/DialogContactFormular.vue";
import DialogWhatsapp from "../dialogs/DialogWhatsapp.vue";

export default {
  components: { DialogTelephone, DialogContactFormular, DialogWhatsapp },
  computed: {
    openings() {
      return openings;
    },
    isOpen() {
      const format = "H:mm";
      let todayEntry = [];

      todayEntry = Object.entries(this.openings)[dayjs().get("day") - 1] || [];

      if (todayEntry.length < 1 || todayEntry[1].length < 1) {
        return false;
      }

      const from_morning = dayjs(todayEntry[1][0], format);
      const to_morning = dayjs(todayEntry[1][1], format);
      const from_afternoon = dayjs(todayEntry[1][2], format);
      const to_afternooon = dayjs(todayEntry[1][3], format);

      return (
        dayjs().isBetween(from_morning, to_morning) ||
        dayjs().isBetween(from_afternoon, to_afternooon)
      );
    },
  },
  methods: {
    openMap() {
      this.$emit("toggleDialog");
      this.$router.replace("/contactmap");
    },
  },
};
</script>
<style scoped>
.entries {
  font-size: smaller;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sub-entry {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgb(179, 179, 179);
  padding-top: 5px;
  padding-bottom: 5px;
}

.entry-hour {
  margin-right: 2rem;
  width: 6rem;
}

.entry-day {
  font-weight: 600;
}

.is-open {
  text-align: center;
}

.is-open-line {
  font-weight: 600;
}

.is-open-container {
  padding-bottom: 1rem;
}
</style>